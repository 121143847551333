html {
  /*position: relative;
  min-height: 100%;*/
}
body, .sticky-footer-wrapper {
   min-height:100vh;
}

.flex-fill {
   flex:1 1 auto;
}
body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*margin-bottom: 60px;*/
}

.app {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

input.error {
  border-color: red;
}

.input-feedback {
  color: red;
  height: 5px;
  margin-top: -12px;
}

#navigation-wrapper {
  /*background-color: #CC3300;*/
}

.widget-title {
  /*background: #1884b8;
  background: #e73737; 69D2E7 FF4E50
  background:#CC333F;
  border-left: 8px solid rgba(0, 0, 0, .2);*/
  background:#000000;
  border-left: 8px solid #CC333F;
  color: #FFF;
  display: block;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 13px 10px 14px 10px;
  width: 100%;
}

/************************************************************
SEARCH CSS
************************************************************/

.domain-search {
  margin: 0;
  padding: 30px 0 30px 0;
  color:#000000;
}

.bg-pblue {
  /*background-color: #35abdf*/
}

.domain-search .form-title {
  margin-top: 0;
  margin-bottom: 2px;
  color: #000000;
  font-weight: 300;
}

.domain-search p {
  color: #999999;
  margin: 0;
}

.domain-search .input-group {
  -webkit-box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
}

.domain-search .input-group-addon {
  border: none;
}

.domain-search .form-title strong {
  display: block;
}

.domain-search .form-control {
  height: 60px;
  border-radius: 0;
  border: none;
}

.domain-search .form-control:focus {
  box-shadow: 0px 0px 0px 0px #ffffff !important;
}

.input-group .input-group-addon [type="submit"],
.input-group .input-group-addon button {
  border: none;
  border-radius: 0;
}

.domain-search .btn {
  padding: 0 30px;
  line-height: 60px;
}

.btn-primary {
  padding: 0 28px;
  line-height: 54px;
  background: #ff6726;
  color: #000000;
}

.btn-primary:hover {
  color: #000000;
  background-color: #286090;
  border-color: #204d74;
}

.domain-search .input-group+p {
  color: #000000;
  font-size: 15px;
  font-weight: 300;
  margin-top: 15px;
  margin-bottom: 0;
}

.domain-search .slim {
  color: #000000;
  font-size: 15px;
  font-weight: 300;
  margin-top: 15px;
  margin-bottom: 0;
}

.domain-search .input-group+p strong {
  margin-right: 40px;
}

.search-button, .search-button:hover {
  background-color: #CC333F;
  color:#FFFFFF;
}

/************************************************************
SEARCH CSS
************************************************************/


.footer {
  /*position: absolute;
  bottom: 0; 
  width: 100%;
  height: 40px; 
  line-height: 0.5;*/
  background-color: #f5f5f5;
  height:60px;
  text-align:centre;
  color:#000000;
}

main.container {
  /*padding: 60px 15px 0;*/
}

footer a {
  color:#2B364A;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #CC333F;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #CC333F;
}

.display-5 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
}

@media screen and (max-width: 576px) {
  .domain-search {
    margin: 0;
    padding: 0 0 10px 0;
    color:#000000;
  }
}

a {
  color: #CC333F;
}